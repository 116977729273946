.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

:global(#hero-left) {
  & .text {
    text-align: center;
    width: max-content;
    & h3 {
      font-family: 'bon-vivant';
      margin: 0;
      padding: 0;
      color: #2e2825;
      font-weight: 400;
      @media (min-width: 1024px) {
        line-height: 1;
        font-size: 8rem;
      }
      @media (min-width: 1400px) {
        font-size: 148px;
      }
      @media (max-width: 1023px) {
        font-size: 61.031px;
        font-style: normal;
        font-weight: 400;
        line-height: 72px; /* 117.972% */
      }
    }
    & p:not(:last-child) {
      font-family: 'bon-vivant';
      font-size: 1.5rem;
      line-height: 33px;
      max-width: 249px;
      margin: 0 auto 26px;
      padding: 0;
      color: #000;
      font-style: normal;
      font-weight: 300;
      @media (min-width: 1024px) {
        font-size: 1.125rem;
        line-height: 1.375;
      }
      @media (min-width: 1400px) {
        font-size: 1.5rem;
        line-height: 1.375;
      }
      @media (min-width: 1920px) {
      }
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 16.5px;
        max-width: 123px;
      }
    }
    & p:last-child {
      max-width: 240px;
      margin: 0 auto;
      /* & a {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #b06b5d;
        margin-bottom: 20px;
        border-radius: 40px;
        min-height: 54px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 767px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          min-height: 38px;
          max-width: 144px;
        }
      } */
    }
  }
}

:global(#hero-right) {
  & .media {
    & img {
      border-radius: 0;
    }
  }
}

:global(#categories) {
  & .media {
    border-radius: 0;
    max-height: 599px;
    & div {
      border-radius: 0;
      & img {
        border-radius: 0;
        max-height: 599px;
      }
    }
  }
  & .root {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      backdrop-filter: blur(0.3px);
      background-color: #ffffff52;
    }
    &:hover {
      transition: all 0.5s ease;
      &:after {
        bottom: unset;
      }
    }
    & .text {
      margin: 0;
    }
    & h3 {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 6px;
      backdrop-filter: blur(1px);
      background-color: #ffffff9c;
      text-align: center;
      font-family: 'bon-vivant';
      font-size: 43px;
      line-height: 48px;
      @media (min-width: 1024px) {
        font-size: 2.25rem;
        line-height: 2.5rem;
      }
      @media (min-width: 1920px) {
        font-size: 3rem;
        line-height: 1;
      }
      @media (max-width: 1260px) {
        font-size: 2.25rem;
        line-height: 2.5rem;
      }
      @media (max-width: 767px) {
        font-size: 26.016px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
      }
    }
    & p {
      margin: 0;
      padding: 0;
    }
    & a {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      opacity: 0;
      z-index: 10;
      cursor: pointer;
    }
  }
}

:global(#how-it-works) {
  & .root {
    text-align: center;
  }
  & .media {
    max-width: 160px;
    margin: 0 auto;
    @media (max-width: 767px) {
      max-width: 96px;
      max-height: 96px;
    }
  }
  & .text {
    margin-top: 32px;
    & h3 {
      font-family: 'bon-vivant';
      font-size: 22px;
      line-height: 32px;
      font-weight: 700;
      margin: 0 0 7px;
      padding: 0;
      color: #1f2937;
      @media (min-width: 1024px) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }

      @media (min-width: 1920px) {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      @media (max-width: 767px) {
        font-size: 16.453px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
    & p {
      margin: 0;
      padding: 0;
      font-size: 18px;
      line-height: 28px;
      color: #1f2937;
      font-family: Montserrat, sans-serif;
      @media (min-width: 1024px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
      @media (min-width: 1400px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
      @media (min-width: 1920px) {
        font-size: 1.125rem;
        line-height: 1.75rem;
      }
      @media (max-width: 767px) {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */
        letter-spacing: -0.375px;
      }
    }
  }
}

:global(#why-rowely) {
  & .text {
    & h3 {
      font-family: 'bon-vivant';
      font-size: 26px;
      line-height: 36px;
      text-align: center;
      @media (min-width: 1024px) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      @media (min-width: 1400px) {
        font-size: 1.875rem;
        line-height: 2.25rem;
      }
      @media (max-width: 767px) {
        font-size: 15.75px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }
}

.productTypesModalProgress {
  & > div {
    & > div {
      flex-basis: 700px !important;
      @media (max-width: 1126px) {
        flex-basis: 90% !important;
      }
    }
  }
}
.productTypesModal {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media (min-width: 1024px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  @media (min-width: 1400px) {
    font-size: 3rem;
    line-height: 1;
  }
  @media (min-width: 1920px) {
    font-size: 3.75rem;
    line-height: 1;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(17 24 39 / 0.3) !important;
  }
  & > div {
    @media (max-width: 767px) {
      min-height: auto !important;
      background-color: transparent !important;
      flex-grow: unset !important;
    }
    & > div {
      flex-basis: 750px !important;
      @media (min-width: 1024px) {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
        padding-top: 1rem !important;
        padding-bottom: 0.75rem !important;
      }
      & > button {
        padding: 10px !important ;
        & svg {
          width: 20px;
          height: 20px;
        }
      }
      @media (max-width: 1126px) {
        flex-basis: 90% !important;
      }
      @media (max-width: 767px) {
        min-height: auto !important;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
        padding-bottom: 0.75rem !important;
        padding-top: 0.5rem !important;
        max-width: calc(100vw - 60px) !important;
        border-radius: 6px !important;
        & > button {
          padding: 10px !important;
          & svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
.productTypes {
  & .productTypesTitle {
    color: var(--Black, #000);
    text-align: center;
    font-family: 'bon-vivant';
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px; /* 176.471% */
    margin: 0 0 24px;
    @media (min-width: 1024px) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
    @media (min-width: 1400px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media (max-width: 767px) {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      color: rgb(55 65 81 / 1);
      margin-top: 1.25rem;
      margin-bottom: 1rem;
      padding: 0;
    }
  }
  & .productTypesWrapper {
    display: flex;
    gap: 12px;
    @media (max-width: 1126px) {
      flex-wrap: wrap;
    }
    & > div {
      flex: 1;
      @media (max-width: 767px) {
        min-width: calc(50% - 12px);
        & img {
          width: 100%;
        }
      }
    }
    & .categories {
      position: relative;
      cursor: pointer;
      max-width: 226px;
      & img {
        width: 100%;
        object-fit: cover;
        object-position: top;
        /* max-width: 211px; */
        max-height: 316px;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(0.3px);
        backdrop-filter: blur(0.3px);
        background-color: #ffffff52;
        z-index: 2;
      }
      &:hover {
        &:after {
          content: unset !important;
        }
      }
    }
    & .productName {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.95;
      background: rgba(255, 255, 255, 0.7);
      color: #292625;
      text-align: center;
      font-family: 'bon-vivant';
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px; /* 218.182% */
      padding: 8px;
      z-index: 3;
      @media (min-width: 1024px) {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      @media (max-width: 767px) {
        line-height: 1.75rem;
        font-size: 1.25rem;
      }
    }
  }
}
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shopBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b06b5d;
  background-color: transparent;
  margin-bottom: 20px;
  border-radius: 40px;
  min-height: 54px;
  margin-left: auto;
  margin-right: auto;
  max-width: 240px;
  color: var(--Burnt-Rose---Secondary, #b06b5d);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 140% */
  text-transform: lowercase;

  @media (max-width: 767px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    min-height: 38px;
    max-width: 144px;
  }
  &:hover {
    background-color: #000;
    color: #fff;
    text-decoration: none;
  }
}

@media (min-width: 1024px) {
}
@media (min-width: 1400px) {
}
@media (min-width: 1920px) {
}
