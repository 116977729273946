.root {
}

:global(#hero-left) {
  background-color: #efe4d3;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    padding: 20px;
  }
}
