.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
}
.productTypesModalProgress{
  & > div {
    & > div {
      flex-basis: 700px !important;
      @media (max-width: 1126px) {
        flex-basis: 90% !important;
      }
    }
  }
}
.productTypesModal {
  & > div {
    & > div {
      flex-basis: 1061px !important;
      @media (max-width: 1126px) {
        flex-basis: 90% !important;
      }
    }
  }
}
.productTypes {
  & .productTypesTitle {
    color: var(--Black, #000);
    text-align: center;
    font-family: 'bon-vivant';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 176.471% */
    margin: 0 0 24px;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  & .productTypesWrapper {
    display: flex;
    gap: 12px;
    @media (max-width: 1126px) {
      flex-wrap: wrap;
    }
    & > div {
      flex: 1;
      @media (max-width: 767px) {
        min-width: calc(50% - 12px);
        & img {
          width: 100%;
        }
      }
    }
    & .categories {
      position: relative;
      cursor: pointer;
      max-width: 226px;
      & img{
        width: 100%;
        object-fit: cover;
        object-position: top;
        /* max-width: 211px; */
        max-height: 316px;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(0.3px);
        backdrop-filter: blur(0.3px);
        background-color: #ffffff52;
        z-index: 2;
      }
      &:hover {
        &:after {
          content: unset !important;
        }
      }
    }
    & .productName {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.95;
      background: rgba(255, 255, 255, 0.7);
      color: #2e2825;
      text-align: center;
      font-family: 'bon-vivant';
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px; /* 218.182% */
      padding: 8px;
      z-index: 3;
    }
  }
}
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalLoginRequired {
  & > div {
    & > div {
      @media (min-width: 768px) {
        flex-basis: 420px !important;
        padding: 40px 43px !important;
      }
    }
  }
}

.modalLoginRequiredContent {
  & h4 {
    color: var(--Black, #000);
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    margin: 0 0 13px;
  }
  & p {
    color: var(--Black, #000);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 0 auto 21px;
    padding: 0;
    max-width: 334px;
  }
  & a {
    display: flex;
    width: 93.97px;
    height: 36px;
    padding: 8px 23.659px 9px 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: var(--Black, #000);
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    margin: 0 auto;
  }
}
