@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
  background-color: #2e2825 !important;
  & * {
    color: #f3f4f6 !important;
  }
  @media (min-width: 768px) {
    & > div {
      padding-top: 16px !important;
      padding-bottom: 16px !important;
    }
  }
}

.footer {
  max-width: 1440px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 24px 40px;
  @media (max-width: 767px) {
    padding: 24px 16px;
  }
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  display: grid;
  justify-content: center;
  align-content: end;
  grid-auto-rows: auto;
  grid-gap: 32px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'details'
    'grid'
    'links';
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}

.slogan {
  color: #f3f4f6;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  margin: 18px 0 .5rem;
  display: block;
  @media (max-width: 767px) {
    margin: 18px 0 0px;
  }
}
.sloganMobile {
  grid-area: details;
  padding-bottom: 0px;

  @media (--viewportLarge) {
    display: none;
  }
}
.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 24px;
}

.icons {
  margin-top: 24px;

  @media (--viewportLarge) {
    margin-top: 0px;
  }
  & svg{
   color:rgba(171, 169, 168, 1) !important;
   & path{
    color:rgba(171, 169, 168, 1) !important;
   }
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}
.logoWrapper {
  max-width: 370px;
}
.logoImage {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 298px);
  }
}

@media (min-width: 350px) {
  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }
  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }
  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    /* grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'logo . . .'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid'; */
    display: flex;
    gap: 30px;
    & > div {
      flex: 1;
    }
  }

  

  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 767px) {
      order: 2;
    }
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.subscribeNewsLetter {
  max-width: 317px;
  @media (max-width: 767px) {
    order: 3;
  }
  & h6 {
    color: #f3f4f6;
    font-family: 'bon-vivant';
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-style: normal;
    line-height: 28px; /* 160% */
    margin: 0 0 0px;
    text-transform: capitalize;
    font-weight: 300;
    @media (max-width: 767px) {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }
  & p {
    color: #f3f4f6;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 6px 0 0;
    &:last-child{
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.inputWrapper {
  display: flex;
  position: relative;
  & button {
    border: 0;
    box-shadow: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 9px;
  }
  & input {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    border-bottom: 1px solid #e5e7eb;
    padding-left: 0;
    padding-top: 0;
    &:focus {
      box-shadow: none;
      border: 0;
      border-bottom: 1px solid #e5e7eb;
      background-color: transparent;
    }
    &::placeholder {
      color: #fff;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}

.logoSection {
  max-width: 315px;
  & svg{
    height: 48px;
  }
  @media (max-width: 767px) {
    order: 1;
  }
}

.logoText{
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  font-family: 'bon-vivant';
  color: #fff;
  line-height: 48px;
  display: inline-block;
  & span{
    display: inline-block;
  }
}