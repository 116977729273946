@import '../../../styles/customMediaQueries.css';

/* Styles for custom sections: classes shared by <SectionColumns>, <SectionArticle>, <SectionFeatures>, <SectionCarousel>, <SectionHero> etc. */
.sectionDetails {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  justify-content: start;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;

  & h1 + p,
  & h1 + a,
  & h1 + ul,
  & h1 + ol,
  & h1 + code,
  & h1 + div,
  & h1 + h1,
  & h1 + h2,
  & h1 + h3,
  & h1 + h4,
  & h1 + h5,
  & h1 + h6 {
    margin-top: 12px;
  }

  & h2 + p,
  & h2 + a,
  & h2 + ul,
  & h2 + ol,
  & h2 + code,
  & h2 + div,
  & h2 + h1,
  & h2 + h2,
  & h2 + h3,
  & h2 + h4,
  & h2 + h5,
  & h2 + h6 {
    margin-top: 12px;
  }

  & h3 + p,
  & h3 + a,
  & h3 + ul,
  & h3 + ol,
  & h3 + code,
  & h3 + div,
  & h3 + h1,
  & h3 + h2,
  & h3 + h3,
  & h3 + h4,
  & h3 + h5,
  & h3 + h6 {
    margin-top: 12px;
  }

  & h4 + p,
  & h4 + a,
  & h4 + ul,
  & h4 + ol,
  & h4 + code,
  & h4 + div,
  & h4 + h1,
  & h4 + h2,
  & h4 + h3,
  & h4 + h4,
  & h4 + h5,
  & h4 + h6 {
    margin-top: 12px;
  }

  & h5 + p,
  & h5 + a,
  & h5 + ul,
  & h5 + ol,
  & h5 + code,
  & h5 + div,
  & h5 + h1,
  & h5 + h2,
  & h5 + h3,
  & h5 + h4,
  & h5 + h5,
  & h5 + h6 {
    margin-top: 12px;
  }

  & h6 + p,
  & h6 + a,
  & h6 + ul,
  & h6 + ol,
  & h6 + code,
  & h6 + div,
  & h6 + h1,
  & h6 + h2,
  & h6 + h3,
  & h6 + h4,
  & h6 + h5,
  & h6 + h6 {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    justify-content: center;
  }
}

.blockContainer {
  padding: 64px 32px 0 32px;

  &:first-child {
    padding: 0 32px;
  }
}

.align {
  text-align: left;
  justify-self: start;

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
  }
}

.title {
  composes: align;
  max-width: 30ch;
}

.description {
  composes: align;
  max-width: 65ch;
}

.ctaButton {
  composes: align;
  display: inline-block;
  padding: 8px 20px;
  font-size: 15px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin-top: 24px;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
  }
}

/**
 * Theme: dark 
 * These styles are at the bottom of the file so that they overwrite rules for default "light" theme.
 */
.darkTheme h1,
.darkTheme h2,
.darkTheme h3,
.darkTheme h4,
.darkTheme h5,
.darkTheme h6 {
  color: var(--colorWhite);

  &::selection {
    background-color: cyan;
    color: unset;
  }
}

.darkTheme p,
.darkTheme li,
.darkTheme blockquote {
  color: rgba(255, 255, 255, 0.85);

  &::selection {
    background-color: cyan;
    color: unset;
  }
}

/* link on dark theme */
.darkTheme a {
  color: white;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorLight);
  }
}

/* button on dark theme */
.darkTheme .ctaButton {
  border: 1px solid var(--marketplaceColorDark);
  text-decoration: none;

  &:hover {
    color: white;
  }
}

.darkTheme hr {
  border-color: var(--colorGrey700);
}

/* dark inline code */
.darkTheme code {
  background-color: var(--colorGrey700);
  color: var(--colorGrey100);
}

/* dark code block */
.darkTheme pre {
  background-color: var(--colorGrey700);
}

:global(#landing-hero) {
  & .blockContainer {
    max-width: 100%;
    padding: 0;
    gap: 0;
    grid-template-columns: repeat(2, calc((100% - 0px) / 2));
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
      padding: 8px 20px;
    }
  }
}

:global(#categories) {
  & .blockContainer {
    max-width: 100%;
    padding: 0 20px;
    gap: 1.5rem;
    @media (min-width:1260px) {
      padding: 0 80px;
      gap: 48px;
      grid-template-columns: repeat(4, calc((100% - 3 * 48px) / 4));
    }
  }
}

:global(#how-it-works) {
  @media (max-width: 767px) {
    & header {
      display: block;
      margin-bottom: 25px;
    }
  }
  & h2 {
    font-family: 'bon-vivant';
    font-size: 55px;
    line-height: 60px;
    font-weight: 400;
    @media (min-width: 1024px) {
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
    @media (min-width: 1400px) {
      font-size: 3rem;
      line-height: 1;
    }
    @media (min-width: 1920px) {
      font-size: 3.75rem;
      line-height: 1;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      text-align: center;
      font-size: 22.125px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      width: 100%;
    }
  }
}
:global(#why-rowely) {
  @media (max-width: 767px) {
    & header {
      display: block;
    }
  }
  & h2 {
    font-family: 'bon-vivant';
    font-size: 55px;
    line-height: 60px;
    font-weight: 400;
    @media (max-width: 767px) {
      max-width: 100%;
      text-align: center;
      font-size: 22.125px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      width: 100%;
    }
  }
  & .blockContainer {
    max-width: 1440px;
  }
}

:global(#news-letter) {
  & .blockContainer {
    max-width: 1440px;
    padding: 0 40px;
    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }
}

:global(.news-letter-row) {
  display: flex;
  align-items: flex-end;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
  }
  & > div {
    flex: 1;
    @media (max-width: 767px) {
      width: 100%;
    }
    & h3 {
      color: #000;
      font-family: 'bon-vivant';
      font-size: 42.75px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px; /* 112.281% */
      @media (min-width: 1024px) {
        font-size: 1.875rem;
        line-height: 2.25rem;
      }
      @media (min-width: 1400px) {
        font-size: 2.25rem;
        line-height: 2.5rem;

      }
      @media (min-width: 1920px) {
        font-size: 3rem;
        line-height: 1;
      }
      @media (max-width: 767px) {
        font-size: 21.375px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
      }
    }
    & p {
      color: #000;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 140% */
      padding-bottom: 0;

      @media (min-width: 1280px) {
        font-size: 1.125rem;
        line-height: 1.75rem;
      }
      @media (min-width: 1920px) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

:global(.news-letter-input) {
  display: flex;
  gap: 24px;
  align-items: flex-end;
  & input {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    border-bottom: 1px solid #9ca3af;
    padding: 0 0 6px;
    &:focus {
      box-shadow: none;
      border: 0;
      border-bottom: 1px solid #9ca3af;
      background-color: transparent;
    }
    &::placeholder {
      color: #9ca3af;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
  & button {
    max-width: 216px;
    border-radius: 40px;
    background-color: #b06b5d;
    font-size: 1.5rem;
    line-height: 2rem;
    &:hover{
      background-color: #b06b5d;
    }
    @media (max-width: 767px) {
      max-width: 91px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      min-height: 40px;
    }
  }
}
