.root {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  /* we style global elements only because they are generated by markdown processor */
  & a {
    color: var(--colorGrey700);
    color: #f3f4f6;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    @media (max-width:767px) {
      font-size: 13px;
      line-height: 17px;
    }
    &:hover {
      color: var(--marketplaceColor);
      text-decoration: none;
    }
  }

  & ol {
    margin-top: 0px;
    padding-left: 0px;
  }

  & li {
    font-size: 14px;
    color: var(--colorGrey700);
    padding: 0px;
    list-style-position: inside;
    margin-bottom: 4px;
    @media (max-width:767px) {
 
      line-height: 20px;
    }
  }

  & p {
    padding: 0px;
    color: #f3f4f6;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
    margin-bottom: 10px;
    @media (max-width:767px) {
 
      font-size: 15px;
    }
  }
}
