.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.tab {
  display: flex;
  justify-content: center;
  margin: 23px 0 64px;
  & p {
    width: 110px;
    text-align: center;
    text-align: center;
    font-family: 'bon-vivant';
    font-size: 22px;
    line-height: 32px;
    border-bottom: 2px solid #d1d5db;
    padding: 0 0 10px;
    margin: 0;
    cursor: pointer;
    @media (min-width: 1024px) {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    @media (min-width: 1920px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media (max-width: 767px) {
      font-size: 16.594px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
    &.active {
      border-bottom: 2px solid #000;
    }
  }
}

.blockItems {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  & > div {
    max-width: 360px;
  }
}

.sectionTab {
  display: block !important;
  max-width: 100%;
  padding: 0 84px !important;
  @media (max-width: 1919px) {
    padding: 0 36px !important;
  }
  @media (max-width: 767px) {
    padding: 0 20px !important;
  }
}

:global(#refunds) {
  & a {
    color: #1d4ed8;
  }
  & p {
    font-weight: 400;
    line-height: 1.75rem !important;
  }
  & li {
    font-weight: 400;
    line-height: 1.75rem !important;
    padding: 0;
  }
  & > div {
    padding: 0;
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;
  }
  & h4 {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
  }
  & h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.75rem !important;
    padding: 0;
  }
  & h6 {
    font-size: 16px;
    line-height: 22px;
    text-transform: initial;
    font-weight: 400;
    line-height: 1.75rem !important;
    /* display: flex;
    align-items: flex-start;
    gap: 10px; */
    padding-left: 30px;
    position: relative;
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      background-color: #000;
      border-radius: 50%;
      display: block;
      min-width: 5px;
      min-height: 5px;
      margin-top: 8px;
      position: absolute;
      left: 15px;
    }
  }
  & h6 ~ ul {
    margin-left: 20px;
    & li {
      list-style: circle;
    }
  }
  & ul {
    margin-top: 0;
  }
  & .articleMain {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  & h6 {
    margin-top: 0;
  }
  @media (min-width: 1400px) {
    .articleMain {
      max-width: 1250px;
      margin: 0 auto;
    }
  }
  @media (min-width: 1920px) {
    & .articleMain {
      max-width: 1396px;
      margin: 0 auto;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

}
