@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

:global(#landing-hero) {
  & .sectionContent {
    padding-top: 0;
  }
}

:global(#categories) {
  & .sectionContent {
    padding-top: 0;
    background-color: #fff;
  }
}

:global(#how-it-works) {
  & .sectionContent {
    padding-top: 50px;
    background-color: #fff;
  }
  & img{
    max-height: 160px;
    @media (max-width:1919px) {
      max-height: 128px;
      max-width: 128px;
    }
  }
}

:global(#news-letter) {
  & .sectionContent {
    background: #efe4d3;
    @media (min-width: 1280px) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
}

.testSlider{
  max-width: 100vw;
}
